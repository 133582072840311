import { ORDER_PAYMENT_TYPE } from "schedule-v2/constants";

const isInstalmentPaymentType = (paymentType) => {
  return (
    paymentType === ORDER_PAYMENT_TYPE.INITIAL_PART_PAYMENT ||
    paymentType === ORDER_PAYMENT_TYPE.PART_PAYMENT_INSTALLMENTS
  );
};

export const shouldRenderActionColumnCell = (record) => {
  const { payment_type: paymentType } = record;
  return isInstalmentPaymentType(paymentType);
};
