import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useListController } from "react-admin";
import module_style from "../../Style.module.css";

import { is_empty } from "utils/validations";
import dataProvider from "data/dataProvider";
import constants from "constants/constants";
import { getIfSlotsAvailable } from "utils/Utils";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import api from "data/APIs";
import { useNotifications, useToggleState } from "utils/hooks";

import EmptyListIcon from "assets/vectors/groupPeople.svg";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import SessionStorageConstants from "constants/SessionStorage.constants";
import { useHistory } from "react-router";
import { appendUrlParams } from "utils/urlUtils";
import {
  fetchListingDetails,
  getFunnelStats,
  getPlanFunnelStats,
  prepareRecipientList,
} from "./helpers";
import { useLocation } from "react-router";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import useOrgMemberList from "utils/hooks/useOrgMemberList";
import useCustomColumnsConfig from "utils/hooks/useCustomColumnsConfig";
import { bookings_section_name } from "ui/pages/schedule/BookedSession/bookedSession.constants";
import { booking_record_slug } from "constants/schedule";
import useMerchandiseShippingStatus from "features/Listings/utils/useMerchandiseShippingStatus";
import { isScheduleTypeListing } from "features/Listings/utils/Listings.utils";
import { ORDER_PAYMENT_TYPE } from "schedule-v2/constants";
import { isClassType } from "utils/listing/listingUtils";
import useCustomerDetails from "utils/hooks/useCustomerDetails";

const useListingCustomers = (props) => {
  const {
    getFilterConfig,
    getTableConfigDefault,
    getTableConfigNotDecided,
    getBroadcastActions,
    setScrollPosition,
  } = props;
  const { notify } = useNotifications();
  const history = useHistory();
  const isDesktop = useDesktopMediaQuery();
  const { orgMembers } = useOrgMemberList();
  const {
    processing,
    isDispatchModalActive,
    hideDispatchModal,
    handleUpdateShippingStatus,
    handleShowShippingStatusUpdateModal,
  } = useMerchandiseShippingStatus();
  const { handleBookingNavigationToCustomer } = useCustomerDetails();
  const listing_customers_id = sessionStorage.getItem(
    SessionStorageConstants.LISTING_CUSTOMER_ID
  );

  // entity_data represents the entity which is created over this offering, eg. Plan or Ads
  const entity_data = JSON.parse(
    sessionStorage.getItem(SessionStorageConstants.ENTITY_DATA)
  );
  // plan_id represents the plan id if come through plan journey
  const plan_id = sessionStorage.getItem(SessionStorageConstants.PLAN_ID);

  const [modalData, setModalData] = React.useState(null);
  const [isInfoModalActive, showInfoModal, hideInfoModal] =
    useToggleState(false);

  const location = useLocation();

  const { loaded, data, selectedIds, onSelect, filterValues, total, perPage } =
    useListController(props);
  const hasPagination = total > perPage;
  const isEmptySelectedIds = is_empty(selectedIds);
  const isEmpty = loaded && is_empty(data) && is_empty(filterValues);
  const [slots_data, set_slots_data] = useState(null);
  const [listing_data, set_listing_data] = useState(null);
  const [variantsData, setVariantsData] = useState();
  const [loading, set_loading] = useState(true);
  const [show_modal, set_show_modal] = useState(false);
  const [show_add_modal, set_show_add_modal] = useState(false);
  const [show_more, set_show_more] = useState(false);
  const [show_answer_modal, set_show_answer_modal] = useState(false);
  const [answer_modal_data, set_answer_modal_data] = useState(null);
  const [appointments, set_appointments] = useState([]);
  const availibility_type = listing_data?.availability_choice;
  const [able_to_add, set_able_to_add] = useState(false);
  const [showUpsellModal, setShowUpsellModal] = useState(false);
  const [showPurchasesModal, setShowPurchasesModal] = useState(false);
  const [showAttendeesModal, setShowAttendeesModal] = useState(false);
  const [upsellCardsArray, setUpsellCardsArray] = useState([]);

  const [isBroadcastModalActive, showBroadcastModal, hideBroadcastModal] =
    useToggleState(false);

  const listingsCustomersType = listing_data?.type;

  const isQuickPaymentPage =
    listingsCustomersType === constants.scheduleTypesId.merchandise ||
    listingsCustomersType === constants.scheduleTypesId.no_schedule;

  let unique_customer_count = listing_data?.unique_customers_count;
  if (!is_empty(entity_data)) {
    unique_customer_count = total;
  }
  const isCustomerDetailsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );

  //@DEV(muskan) todo
  //Following checks to be moved to BE in CRM R4
  //BLOCK START
  const isTransactionsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS
  );

  const isListingScheduleType = isScheduleTypeListing(listing_data?.type);
  const isOfferingAutoDebitEnabled =
    listing_data?.subscription_config?.is_auto_debit_active;
  const paymentType = listing_data?.payment_plan_type;
  const isInstallmentPaymentType =
    paymentType === ORDER_PAYMENT_TYPE.INITIAL_PART_PAYMENT ||
    paymentType === ORDER_PAYMENT_TYPE.PART_PAYMENT_INSTALLMENTS;
  const isAppointment =
    listing_data?.type === constants.scheduleTypesId.one_on_one_appointment;
  const isClass = isClassType(listingsCustomersType);

  const isMerchandise =
    listing_data?.type === constants.scheduleTypesId.merchandise;

  const isQPP = listing_data?.type === constants.scheduleTypesId.no_schedule;

  const isRecordedContent =
    listing_data?.type === constants.scheduleTypesId.content;

  const isWebinar = listing_data?.type === constants.scheduleTypesId.webinar;
  const isAvailabilityNotDecided =
    availibility_type === constants.avaibility_type.not_decided;
  //BLOCK END
  const hasWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SUBSECTIONS.MY_BOOKINGS.BOOKINGS
  );
  const canAddCustomers = able_to_add && hasWriteAccess && !isMerchandise;

  const canSendWhatsapp = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
  );
  const canSendEmail = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );
  const canBroadcastMessage = (canSendEmail || canSendWhatsapp) && !isEmpty;
  const showAddCustomerAction = canAddCustomers && !isEmpty;
  const showActions = showAddCustomerAction || canBroadcastMessage;

  const {
    appendCustomTableConfig,
    getCustomColumnsToExport,
    customColumnsHeaders,
  } = useCustomColumnsConfig({
    sectionName: bookings_section_name,
    recordSlug: booking_record_slug,
    setScrollPosition,
  });

  const {
    customFieldToRenderInDetails,
    customColumnsToRender,
    customFiltersToAppend,
    customChipsToFormat,
  } = appendCustomTableConfig({
    orgMembers,
    disabled: !hasWriteAccess,
  });

  const handleFetchListingDetails = () => {
    fetchListingDetails(
      listing_customers_id,
      set_able_to_add,
      getIfSlotsAvailable,
      listingsCustomersType,
      set_appointments,
      setVariantsData,
      set_listing_data,
      notify,
      set_loading
    );
  };

  useEffect(() => {
    handleFetchListingDetails();

    return () => {
      // Clearing LocalStorage on Component unmount
      const navigatingInModule =
        history.location.pathname ===
          `/${app_route_ids[app_route_keys.compose_email]}` ||
        history.location.pathname ===
          app_route_ids[app_route_keys.manage_communications_whatsapp_select] ||
        history.location.pathname ===
          app_route_ids[app_route_keys.manage_communications_whatsapp_publish];
      if (!navigatingInModule) {
        window.sessionStorage.removeItem(SessionStorageConstants.ENTITY_DATA);
        window.sessionStorage.removeItem(SessionStorageConstants.PLAN_ID);
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (plan_id && !is_empty(plan_id)) {
        const planFunnelStats = await getPlanFunnelStats(plan_id);
        setUpsellCardsArray(planFunnelStats);
      } else if (listing_data?.uuid) {
        const funnelStats = await getFunnelStats(listing_customers_id);
        setUpsellCardsArray(funnelStats);
      }
    })();
  }, [listing_data, plan_id]);

  useEffect(() => {
    if (location.pathname.includes("upsell")) {
      setShowUpsellModal(true);
    } else if (location.pathname.includes("multiplePurchases")) {
      setShowPurchasesModal(true);
    } else if (location.pathname.includes("attendees")) {
      setShowAttendeesModal(true);
    }
  }, [location]);

  const showSlots = useCallback(
    async (record) => {
      try {
        const data = await dataProvider.custom_request(
          `${api.get_customer_slots}?transaction_uuid=${record.transaction_uuid}&customer_uuid=${record.username}`,
          "GET"
        );
        if (data.status === 200) {
          await set_slots_data(data.data.slots);
          set_show_modal(true);
        }
      } catch (err) {
        console.log("Something went wrong while fetching customer slots.", err);
        if (!is_empty(err.body) && !is_empty(err.body.message)) {
          notify(err.body.message, "error");
        }
      }
    },
    [set_slots_data, set_show_modal, notify]
  );

  const handleAnswersClick = useCallback(
    (record) => {
      set_answer_modal_data(record);
      set_show_answer_modal(true);
    },
    [set_answer_modal_data, set_show_answer_modal]
  );

  const sendEmail = useCallback(() => {
    if (isDesktop && isEmptySelectedIds)
      return notify("No contact selected!", "error");

    prepareRecipientList(data, selectedIds);
  }, [selectedIds, data]);

  const getColumnConfig = () => {
    const tableConfigProps = {
      isClass,
      isAppointment,
      listing_data,
      entity_data,
      showSlots,
      handleAnswersClick,
      handleShowShippingStatusUpdateModal,
      sendEmail,
      availibility_type,
      isCustomerDetailsHidden,
      isTransactionsHidden,
      isWebinar,
      customColumnsToRender,
      isQPP,
      isRecordedContent,
      isListingScheduleType,
      isOfferingAutoDebitEnabled,
      isInstallmentPaymentType,
      isMerchandise,
      variantsData,
      handleBookingNavigationToCustomer,
    };
    if (isAvailabilityNotDecided) {
      if (getTableConfigNotDecided)
        return getTableConfigNotDecided(tableConfigProps);
    } else {
      if (getTableConfigDefault) return getTableConfigDefault(tableConfigProps);
    }
  };

  const columnConfig = useMemo(() => {
    return getColumnConfig();
  }, [
    isClass,
    isQPP,
    isRecordedContent,
    isAppointment,
    listing_data,
    showSlots,
    handleAnswersClick,
    sendEmail,
    isAvailabilityNotDecided,
    isWebinar,
  ]);

  const filterConfig = getFilterConfig
    ? getFilterConfig({
        isClass,
        variantsData,
        customFiltersToAppend,
        isListingScheduleType,
        entity_data,
        isOfferingAutoDebitEnabled,
        isInstallmentPaymentType,
        isAppointment,
        isWebinar,
        isMerchandise,
        listingData: listing_data,
      })
    : [];

  const handleMore = async () => {
    await set_show_more(true);
    document
      .querySelector("#listing_desc_container")
      .setAttribute("style", "max-height: 230px; overflow: hidden;");
  };

  const sendWhatsappBroadcast = useCallback(() => {
    if (isEmptySelectedIds) return notify("No contact selected!", "error");

    const phoneNumberList = selectedIds.map(
      (i) => `${data[i]?.customer_country_code}${data[i]?.customer_phone}`
    );

    history.push(
      appendUrlParams("/whatsapp-broadcasts/select", {
        preselected_numbers: encodeURIComponent(phoneNumberList.join(",")),
      })
    );
  }, [selectedIds]);
  /**
   * TODO: Revamp this like the component used in webapp
   * src/components/common/ReadMoreWrapper/index.tsx
   */
  useEffect(() => {
    if (
      document.querySelector("#listing_desc_container") &&
      !show_more &&
      document.querySelector("#listing_desc_container").offsetHeight > 260
    ) {
      handleMore();
    }
  });

  const footerProps = {
    primarybtn: "Send Message",
    primaryClick: showBroadcastModal,
    primaryBtnProps: {
      fullWidth: isMerchandise,
    },
    hidePrimarybtn: !canBroadcastMessage,
    secondaryBtn: "Add Customers",
    secondaryClick: () => set_show_add_modal(true),
    hideSecondaryBtn: !showAddCustomerAction || isMerchandise,
  };

  const emptyStateProps = {
    content_title: "No offering customers yet.",
    empty_list_icon: EmptyListIcon,
    alt: "no participants",
    classes: { content_wrapper: module_style.listing_empty_wrapper },
    primary_cta: canAddCustomers
      ? {
          children: "Add customer",
          onClick: () => set_show_add_modal(true),
        }
      : null,
  };

  const broadcastActions = useMemo(() => {
    if (getBroadcastActions)
      return getBroadcastActions({
        sendEmail,
        sendWhatsappBroadcast,
      });
  }, [sendEmail, sendWhatsappBroadcast, selectedIds, data]);

  return {
    isDesktop,
    isMerchandise,
    listing_data,
    entity_data,
    variantsData,
    unique_customer_count,
    availibility_type,
    total,
    hasPagination,
    selectedIds,
    onSelect,
    columnConfig,
    filterConfig,
    isCustomerDetailsHidden,
    showActions,
    footerProps,
    showInfoModal,
    isAppointment,
    isQPP,
    isRecordedContent,
    handleShowShippingStatusUpdateModal,
    isQuickPaymentPage,
    emptyStateProps,
    canBroadcastMessage,
    isBroadcastModalActive,
    hideBroadcastModal,
    broadcastActions,
    slots_data,
    set_slots_data,
    show_add_modal,
    show_modal,
    set_show_modal,
    set_show_add_modal,
    isInfoModalActive,
    modalData,
    setModalData,
    hideInfoModal,
    showSlots,
    show_answer_modal,
    set_show_answer_modal,
    answer_modal_data,
    isDispatchModalActive,
    hideDispatchModal,
    processing,
    handleUpdateShippingStatus,
    appointments,
    loading,
    isClass,
    show_more,
    canAddCustomers,
    showBroadcastModal,
    showUpsellModal,
    setShowUpsellModal,
    showPurchasesModal,
    setShowPurchasesModal,
    showAttendeesModal,
    setShowAttendeesModal,
    upsellCardsArray,
    isWebinar,
    getCustomColumnsToExport,
    customChipsToFormat,
    orgMembers,
    customColumnsHeaders,
    customFieldToRenderInDetails,
    handleFetchListingDetails,
    showAddCustomerAction,
    isListingScheduleType,
    isOfferingAutoDebitEnabled,
    isInstallmentPaymentType,
  };
};

export default useListingCustomers;
