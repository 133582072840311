import {
  DateTimeField,
  WhatsAppPhoneField,
} from "common/Components/TableFields/TableFields";
import { api } from "data";
import React from "react";
import ActionFieldCustom from "ui/Fields/ActionFieldCustom";
import EmailField from "ui/Fields/EmailField";
import {
  form_data,
  subscription_listing_filter_keys,
  statusFilter,
  AUTO_DEBIT_STATUS_FILTER,
} from "./NewCustomers.constants";
import SessionStorageConstants from "constants/SessionStorage.constants";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import {
  listing_api_keys,
  promotional_upsells,
  upsellTypeMap,
} from "../constants";
import { LinkField } from "webpage-leads/components/CustomColumns";
import { upsellTypeOptions } from "constants/upsellConstants";
import { is_empty } from "utils/validations";
import {
  PAYMENT_DEBIT_MODES,
  PAYMENT_DEBIT_MODES_LABELS,
  PAYMENT_DEBIT_MODE_OPTIONS,
} from "features/Listings/modules/PricingSection/PricingSection.constants";
import {
  getStatusHeaderName,
  getSubscriptionEndDateHeaderName,
  getSubscriptionStartDateHeaderName,
} from "./SubscriptionCustomers.utils";
import { isFlexibleOneTime } from "utils/listing/listingUtils";
import { StatusField } from "./Components/StatusFields";
import { FLEXIBLE_ONE_TIME_FILTER_OPTIONS } from "constants/bookings.constants";
import { UTMInfoField } from "common/form/UTMInfoField";
import { isScheduleTypeListing } from "features/Listings/utils/Listings.utils";
import { timezoneOptions } from "ui/modules/modules.utils";
import { listing_customer_country_code } from "features/Listings/Listings.constants";
import { NameField } from "../../AllCustomers/components/TableColumns/TableColumns";
import { see_responses_link_text } from "features/Crm/Crm.constants";
import { BatchField } from "features/Crm/modules/Bookings/components/BatchField/BatchField";
import { getTablePriceFieldValue } from "features/Common/modules/Price/utils/Price.utils";

export const storeCustomerToSessionStorage = (record) => {
  window.sessionStorage.setItem(
    "customer",
    JSON.stringify({ ...record, phone_number: record.customer_phone })
  );
  window.sessionStorage.setItem(
    SessionStorageConstants.GUEST_USER_NAME,
    record.username
  );
};

export const columnKeys = {
  customerName: "customer_name",
  customerEmail: "customer_email",
  customerPhone: "customer_phone",
  price: "currency_price",
  planName: "purchased_plan_name",
  variationPurchased: "offering_variant_title",
  bookedOn: "booked_on",
  subscriptionStartDate: "subscription_start_date",
  subscriptionEndDate: "subscription_end_date",
  status: "status",
  upsellType: "upsell_type",
  sourceUpsellOfferingTitle: "source_upsell_offering_title",
  customerTimezone: "customer_timezone",
  answerCount: "answer_count",
  payment_debit_mode: "payment_debit_mode",
  batch_start_date: "batch_start_date",
  batch_end_date: "batch_end_date",
  batch: "class_group_uuid",
};

export const columnConfig = ({
  nextDueDateValue,
  isCustomerDetailsHidden,
  handleAnswersClick,
  customColumnsToRender,
  showVariationColumn,
  listing_data,
  isOfferingAutoDebitEnabled,
  isTransactionsHidden,
  isClass,
  isSubscriptionOrFlexibleOneTime,
  handleBookingNavigationToCustomer,
}) => {
  let columnsList = [
    {
      field: columnKeys.customerName,
      headerName: form_data.name,
      valueFormatter: (record) => (
        <NameField
          record={record}
          source={columnKeys.customerName}
          onClick={handleBookingNavigationToCustomer}
        />
      ),
      isPrimary: true,
      isDrawerTitle: true,
      fixed: "left",
      avatar: true,
      columnClassName: "word_break",
      width: "200px",
    },
    {
      field: columnKeys.customerEmail,
      headerName: form_data.email,
      valueFormatter: (record) => (
        <EmailField
          record={record}
          source={columnKeys.customerEmail}
          hideIcon
        />
      ),
      width: "10%",
      isUnique: true,
      noWrap: true,
      hidden: isCustomerDetailsHidden,
      hiddenDesktopColumn: isCustomerDetailsHidden,
    },
    {
      field: columnKeys.customerPhone,
      headerName: form_data.phone,
      valueFormatter: (record) => (
        <WhatsAppPhoneField
          record={record}
          source={columnKeys.customerPhone}
          countryCodeKey={listing_customer_country_code}
        />
      ),
      noWrap: true,
      hidden: isCustomerDetailsHidden,
      hiddenDesktopColumn: isCustomerDetailsHidden,
    },
    {
      field: columnKeys.batch,
      headerName: form_data.batch,
      valueFormatter: (record) => (
        <BatchField
          listingData={listing_data}
          record={record}
          source={columnKeys.batch}
        />
      ),
      sortable: false,
      noWrap: true,
      hidden: !isClass,
      hiddenDesktopColumn: !isClass,
    },
    ...(listing_data?.has_dropdown_plans
      ? [
          {
            field: columnKeys.planName,
            headerName: form_data.plan_name,
            valueFormatter: (record) => record.purchased_plan_name || "-",
            noWrap: true,
          },
        ]
      : []),
    ...(showVariationColumn
      ? [
          {
            field: columnKeys.variationPurchased,
            headerName: form_data.variationPurchased,
            valueFormatter: (record) => record?.offering_variant_title || "-",
            noWrap: true,
          },
        ]
      : []),
    ...(isOfferingAutoDebitEnabled
      ? [
          {
            field: columnKeys.payment_debit_mode,
            headerName: form_data.payment_debit_mode,
            valueFormatter: (record) =>
              PAYMENT_DEBIT_MODES_LABELS[record.payment_debit_mode] ?? "-",
            noWrap: true,
          },
        ]
      : []),
    {
      field: columnKeys.price,
      headerName: form_data.price,
      // TODO: replace price logic all over tool to keep it consistent
      valueFormatter: (record) =>
        getTablePriceFieldValue(record[columnKeys.price]),
      sortable: false,
      hidden: isTransactionsHidden,
      hiddenDesktopColumn: isTransactionsHidden,
      noWrap: true,
    },
    {
      field: columnKeys.bookedOn,
      headerName: form_data.booked_on,
      valueFormatter: (record) => (
        <DateTimeField record={record} source={columnKeys.bookedOn} />
      ),
      width: "10%",
      isUnique: true,
      noWrap: true,
    },
    ...(isSubscriptionOrFlexibleOneTime
      ? [
          {
            field: columnKeys.status,
            headerName: getStatusHeaderName(listing_data),
            emptyField: "-",
            valueFormatter: (record) =>
              isFlexibleOneTime(listing_data) ? (
                <StatusField
                  record={record}
                  showTooltip={false}
                  offeringData={listing_data}
                />
              ) : (
                <ActionFieldCustom
                  record={record}
                  Nobutton={true}
                  variant="outlined"
                  sortable={false}
                  emptyText={"-"}
                  ActionField={nextDueDateValue}
                  from="transactions"
                  source={columnKeys.status}
                />
              ),
            noWrap: true,
            isCollapsed: true,
          },
          {
            field: columnKeys.subscriptionStartDate,
            headerName: getSubscriptionStartDateHeaderName(listing_data),
            valueFormatter: (record) => (
              <DateTimeField
                record={record}
                source={columnKeys.subscriptionStartDate}
              />
            ),
            width: "10%",
            isUnique: true,
            noWrap: true,
          },
          {
            field: columnKeys.subscriptionEndDate,
            headerName: getSubscriptionEndDateHeaderName(listing_data),
            valueFormatter: (record) => (
              <ActionFieldCustom
                record={record}
                Nobutton={true}
                variant="outlined"
                sortable={false}
                emptyText={"-"}
                ActionField={nextDueDateValue}
                source={columnKeys.subscriptionEndDate}
                return_string={true}
              />
            ),
            isUnique: true,
            noWrap: true,
          },
        ]
      : []),
    {
      field: columnKeys.upsellType,
      headerName: promotional_upsells.upsell_type,
      valueFormatter: (record) => {
        if (upsellTypeMap[record.upsell_type])
          return upsellTypeMap[record.upsell_type];
        else return "-";
      },
      noWrap: true,
      sortable: false,
    },
    {
      field: columnKeys.sourceUpsellOfferingTitle,
      headerName: promotional_upsells.source_upsell_offering_name,
      valueFormatter: (record) => record.source_upsell_offering_title || "-",
      noWrap: true,
      sortable: false,
    },
    {
      field: form_data.utm_info.field,
      headerName: form_data.utm_info.placeholder,
      valueFormatter: (record) =>
        !is_empty(record.utm_info) ? <UTMInfoField record={record} /> : null,
      width: "400px",
      hidden: false,
      hiddenDesktopColumn: false,
    },
    ...(isScheduleTypeListing(listing_data?.type)
      ? [
          {
            field: columnKeys.customerTimezone,
            headerName: form_data.timezone,
            emptyField: "-",
            sortable: false,
          },
        ]
      : []),
    {
      field: columnKeys.answerCount,
      headerName: form_data.check_answers,
      valueFormatter: (record) =>
        record.answer_count ? (
          <LinkField
            record={record}
            source={columnKeys.answerCount}
            linkText={see_responses_link_text}
            onClick={handleAnswersClick}
          />
        ) : (
          <div className="mr-12">-</div>
        ),
      noWrap: true,
      sortable: false,
    },
    /** append custom columns to be shown in table */
    ...customColumnsToRender,
  ];

  return columnsList;
};

const getFilerOptions = ({ listingData }) => {
  if (isFlexibleOneTime(listingData)) {
    return FLEXIBLE_ONE_TIME_FILTER_OPTIONS;
  } else if (listingData?.payment_debit_mode === PAYMENT_DEBIT_MODES.AUTO_DEBIT)
    return AUTO_DEBIT_STATUS_FILTER;
  else return statusFilter;
};

const getBatchOptions = ({ listingData }) => {
  const batchOptions = listingData?.classes?.map((batch, index) => {
    return {
      ...batch,
      label: `${form_data.batch} ${index + 1}`,
      value: batch?.uuid,
    };
  });
  return batchOptions;
};

export const filterConfig = ({
  listingData,
  isNoSchedSub,
  customFiltersToAppend,
  offeringVariations,
  isOfferingAutoDebitEnabled,
  isClass,
  isListingScheduleType,
  isSubscriptionOrFlexibleOneTime,
}) => {
  let filterList = [
    {
      source: listing_api_keys.NAME,
      placeholder: form_data.name,
      type: "input",
      disableContains: true,
    },
    {
      source: subscription_listing_filter_keys.SUBSCRIBED_USER_EMAIL({
        isNoSchedSub,
      }),
      placeholder: form_data.email,
      type: "input",
      disableContains: true,
    },
    {
      source: subscription_listing_filter_keys.SUBSCRIBED_USER_PHONE({
        isNoSchedSub,
      }),
      placeholder: form_data.phone,
      type: "input",
      disableContains: true,
    },
    ...(isClass
      ? [
          {
            source: columnKeys.batch,
            placeholder: form_data.batch,
            type: "dropdown",
            options: getBatchOptions({ listingData }),
          },
        ]
      : []),
    ...(listingData?.has_dropdown_plans
      ? [
          {
            source: columnKeys.planName,
            placeholder: form_data.plan_name,
            type: "input",
            disableContains: true,
          },
        ]
      : []),
    ...(!is_empty(offeringVariations)
      ? [
          {
            source: subscription_listing_filter_keys.OFFERING_VARIATIONS,
            placeholder: form_data.OFFERING_VARIATION,
            options: offeringVariations.map((item) => ({
              ...item,
              value: item.uuid,
              label: item.title,
            })),
            type: "dropdown",
            disableContains: true,
          },
        ]
      : []),
    ...(isOfferingAutoDebitEnabled
      ? [
          {
            source: columnKeys.payment_debit_mode,
            placeholder: form_data.payment_debit_mode,
            options: PAYMENT_DEBIT_MODE_OPTIONS,
            type: "dropdown",
            disableContains: true,
          },
        ]
      : []),
    {
      source: form_data.min_price.source,
      placeholder: form_data.min_price.placeholder,
      type: "input",
      disableContains: true,
    },
    {
      source: form_data.max_price.source,
      placeholder: form_data.max_price.placeholder,
      type: "input",
      disableContains: true,
    },
    {
      source: form_data.booked_on_or_after.field,
      placeholder: form_data.booked_on_or_after.placeholder,
      type: "date",
    },
    ...(isSubscriptionOrFlexibleOneTime
      ? [
          {
            source: subscription_listing_filter_keys.SUBSCRIPTION_STATUS,
            placeholder: form_data.status,
            options: getFilerOptions({ listingData }),
            type: "dropdown",
            disableContains: true,
          },
          {
            source: subscription_listing_filter_keys.CYCLE_START_DATE,
            placeholder: getSubscriptionStartDateHeaderName(listingData),
            type: "date",
            disableContains: true,
          },
          {
            source: subscription_listing_filter_keys.CYCLE_END_DATE,
            placeholder: getSubscriptionEndDateHeaderName(listingData),
            type: "date",
            disableContains: true,
          },
        ]
      : []),
    {
      source: columnKeys.upsellType,
      placeholder: promotional_upsells.upsell_type,
      options: upsellTypeOptions,
      type: "dropdown",
      disableContains: true,
    },
    {
      source: subscription_listing_filter_keys.SOURCE_UPSELL_OFFERING_NAME,
      placeholder: promotional_upsells.source_upsell_offering_name,
      type: "input",
      disableContains: true,
    },
    {
      source: form_data.utm_source.source,
      placeholder: form_data.utm_source.placeholder,
      type: "input",
      disableContains: true,
    },
    {
      source: form_data.utm_medium.source,
      placeholder: form_data.utm_medium.placeholder,
      type: "input",
      disableContains: true,
    },
    {
      source: form_data.utm_campaign.source,
      placeholder: form_data.utm_campaign.placeholder,
      type: "input",
      disableContains: true,
    },
    ...(isListingScheduleType
      ? [
          {
            source: columnKeys.customerTimezone,
            placeholder: form_data.timezone,
            type: "dropdown",
            options: timezoneOptions,
            disableContains: true,
          },
        ]
      : []),
    /** append custom columns to be shown in filter options */
    ...customFiltersToAppend,
  ];

  return filterList;
};

export const tableChipsFormatter = ({
  filter,
  value,
  options,
  customChipsToFormat,
  orgMembers,
  offeringVariations,
  listingData,
}) => {
  const columnToFilter = customChipsToFormat?.find(
    (chipToFormat) => chipToFormat.columnSlug === filter
  );
  if (columnToFilter) {
    const staff = orgMembers?.find((item) => item.id == value);
    return `${columnToFilter?.columnName}: ${staff?.label}`;
  } else {
    switch (filter) {
      case `${listing_api_keys.NAME}`:
        return `${form_data.name}: ${value}`;
      case `${columnKeys.batch}`: {
        const batchOptions = getBatchOptions({ listingData });
        const batchValue = batchOptions?.find(
          (batch) => batch?.value === value
        )?.label;
        return `${form_data.batch}: ${batchValue || ""}`;
      }
      case subscription_listing_filter_keys.SUBSCRIBED_USER_EMAIL(options):
        return `${form_data.email}: ${value}`;
      case subscription_listing_filter_keys.SUBSCRIBED_USER_PHONE(options):
        return `${form_data.phone}: ${value}`;
      case subscription_listing_filter_keys.SUBSCRIPTION_STATUS: {
        const statusFilterOptions = getFilerOptions({ listingData });
        const statusValue =
          statusFilterOptions.find((item) => item.value === value)?.label || "";
        return `${form_data.status}: ${statusValue}`;
      }
      case subscription_listing_filter_keys.UPSELL_TYPE: {
        return `${promotional_upsells.upsell_type}: ${upsellTypeMap[value]}`;
      }
      case columnKeys.payment_debit_mode: {
        return `${form_data.payment_debit_mode}: ${PAYMENT_DEBIT_MODES_LABELS[value]}`;
      }
      case subscription_listing_filter_keys.SOURCE_UPSELL_OFFERING_NAME: {
        return `${promotional_upsells.source_upsell_offering_name}: ${value}`;
      }
      case subscription_listing_filter_keys.OFFERING_VARIATIONS: {
        return `${form_data.OFFERING_VARIATION}: ${
          offeringVariations.find((item) => item.uuid === value)?.title
        }`;
      }
      case subscription_listing_filter_keys.BOOKED_ON:
        return `${form_data.booked_on}: ${value}`;
      case `${form_data.utm_source.source}`:
        return `${form_data.utm_source.placeholder}: ${value}`;
      case `${form_data.utm_medium.source}`:
        return `${form_data.utm_medium.placeholder}: ${value}`;
      case `${form_data.utm_campaign.source}`:
        return `${form_data.utm_campaign.placeholder}: ${value}`;
      case form_data.min_price.source:
        return `${form_data.min_price.placeholder}: ${value}`;
      case form_data.max_price.source:
        return `${form_data.max_price.placeholder}: ${value}`;
      case `${columnKeys.planName}`:
        return `${form_data.plan_name}: ${value}`;
      case columnKeys.customerTimezone:
        return `${form_data.timezone}: ${value}`;
      case subscription_listing_filter_keys.CYCLE_START_DATE:
        return `${getSubscriptionStartDateHeaderName(listingData)}: ${value}`;
      case subscription_listing_filter_keys.CYCLE_END_DATE:
        return `${getSubscriptionEndDateHeaderName(listingData)}: ${value}`;
      default:
        return undefined;
    }
  }
};

export const CLASS_CUSTOMERS_PROPS = {
  basePath: `/${api.customer_list_classes}`,
  history: {
    length: 6,
    action: "POP",
    location: {
      pathname: `/${api.customer_list_classes}`,
      search: "",
      hash: "",
    },
  },
  location: {
    pathname: `/${api.customer_list_classes}`,
    search: "",
    hash: "",
  },
  match: {
    path: `/${api.customer_list_classes}`,
    url: `/${api.customer_list_classes}`,
    isExact: true,
    params: {},
  },
  resource: `${api.customer_list_classes}`,
  options: { label: "Offering class Customers" },
  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: false,
};

//@Todo muskan need to remove this if not needed at the end
export const getBroadcastActions = ({ sendEmail, sendWhatsappBroadcast }) => [
  {
    title: "Send Custom Email",
    description: "Send a Custom Email to the selected leads",
    onClick: sendEmail,
    hidden: !orgPermissions.hasFeatureWriteAccess(
      RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
    ),
  },
  {
    title: "Send WhatsApp Broadcast",
    description: "Send a WhatAapp Broadcast to the selected leads.",
    onClick: sendWhatsappBroadcast,
    hidden: !orgPermissions.hasFeatureWriteAccess(
      RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
    ),
  },
];
