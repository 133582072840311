import React from "react";
import module_style from "../../Style.module.css";
import { is_empty } from "utils/validations";
import constants from "constants/constants";
import BulkActionButton from "features/BulkActions/modules/BulkActionButton/BulkActionButton";
import ExlyImage from "common/Components/ExlyImage";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";

export const BookingTitle = ({
  listing_data,
  entity_data,
  canAddCustomers,
  set_show_add_modal,
  learnMoreHref,
  isSmall,
}) => {
  let _firstAction = {
    text: "Add Customers",
    onClick: () => set_show_add_modal(true),
    icon: (
      <ExlyImage
        src="./assets/vectors/crm/person_add.svg"
        height={20}
        width={20}
        id="tag"
      />
    ),
  };

  const handleGoBackClick = () => {
    window.history.go(-1);
  };

  let entity_title = listing_data?.title;

  if (!is_empty(entity_data)) {
    entity_title = entity_data?.title;
  }
  const isMerchandise =
    listing_data?.type === constants.scheduleTypesId.merchandise;

  return (
    <div className={module_style.topDivWrapper}>
      <span className={module_style.page_title}>
        <span onClick={handleGoBackClick} className="pointer">
          Bookings
        </span>
        {listing_data?.title && (
          <span>
            {` / `}
            <span className={module_style.action_text}>{entity_title}</span>
          </span>
        )}
      </span>
      <div className={module_style.action_wrapper}>
        {learnMoreHref ? <LearnMoreCta href={learnMoreHref} /> : null}
        {canAddCustomers && !isMerchandise && (
          <BulkActionButton isSmall={isSmall} actionItem={_firstAction} />
        )}
      </div>
    </div>
  );
};
